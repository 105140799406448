.concert-card {
    transition: box-shadow 0.2s ease;
  }
  
  .concert-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }

.simpleGridDiv {
  height: 100%;
}